import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import Search from "./tempSearch";
import {
  convertSecondsToHoursMinutes,
  limitTextToWords,
} from "../../../Services/generalFunctions/generalFunctions";
import { useTranslation } from "react-i18next";
import {
  generateFacebookShareUrl,
  generateTwitterShareUrl,
  generateWhatsappShareUrl,
} from "../home/ShareFunction";

const SearchPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const languageCode = queryParams.get("languageCode");
  const term = queryParams.get("term");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation("translation");

  const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
  };
  const [favoriteItems, setFavoriteItems] = useLocalStorage(
    "favoriteItems",
    []
  );
  const [watchListItems, setWatchListItems] = useLocalStorage(
    "watchListItems",
    []
  );

  const source = "app";
  const currentSelectedLanguage = localStorage.getItem("lang");

  useEffect(() => {
    fetchSearchResults();
  }, [languageCode, term]);

  const fetchSearchResults = async () => {
    try {
      const url = `${process.env.REACT_APP_API}/api/home/get-search-content/${languageCode}/${term}`;
      const response = await axios.get(url);
      setSearchResults(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <br />
      <div style={{ marginTop: "80px" }}></div>
      {loading ? (
        <div className="loader">
          <div className="outer"></div>
          <div className="middle"></div>
          <div className="inner"></div>
        </div>
      ) : (
        <section id="iq-favorites" style={{ minHeight: "60vh" }}>
          {searchResults?.content?.length > 0 ? (
            <Container fluid>
              <Row>
                <Col sm="12" className="overflow-hidden">
                  <div
                    className="gallery-container"
                    style={{ marginTop: "20px", marginBottom: "10px" }}
                  >
                    <div className="">
                      <div id="upcoming-contents">
                        <ul className="list-inline mb-0 iq-rtl-direction row">
                          {searchResults?.content?.map((data) => {
                            // if no image, then return null
                            if (data?.thumbnail?.static_thumbnail_url === "") {
                              return null;
                            }
                            return (
                              <li
                                className={`all-card-container slide-item  mb-4 ${
                                  searchResults?.content?.length === 1
                                    ? "col-lg-3"
                                    : "col-lg-3 col-md-4 col-sm-6"
                                }`}
                                key={data._id}
                              >
                                <div className="block-images position-relative">
                                  <div className="card-slider-badge">
                                    {/* <div className="badge badge-secondary p-1 mr-2">
                                      {data?.category === "movie"
                                        ? "VIDEO"
                                        : data?.category?.toUpperCase()}
                                    </div> */}
                                  </div>
                                  <div className="img-box">
                                    <img
                                      src={
                                        data?.thumbnail?.static_thumbnail_url
                                      }
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                   <div className="block-description">
                                    {/* <h6 className="iq-title all-title-desc">
                                      {data?.category === "podcast" ||
                                      data?.category === "tvshow"
                                        ? (currentSelectedLanguage === 'fr' ? data?.title:  data?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated)
                                        : (currentSelectedLanguage === 'fr' ? data?.media?.title:  data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated) }
                                    </h6> */}
                                    <div className="hover-buttons">
                                      <Link
                                        to={
                                          data?.category === "movie" ||
                                          data?.category === "video"
                                            ? `/videos/${data?.slug}`
                                            : `/show-info/${data?._id}`
                                        }
                                        className="btn btn-hover iq-button btn-typ"
                                        style={{ marginTop: "5px" }}
                                      >
                                        <i
                                          className="fa fa-play mr-1"
                                          aria-hidden="true"
                                        ></i>
                                        {t("Play now")}
                                      </Link>
                                    </div>
                                  </div> 
                                  <div className="block-social-info">
                                    <ul className="list-inline p-0 m-0 music-play-lists">
                                      <li className="share">
                                        <span>
                                          <i className="ri-share-fill"></i>
                                        </span>
                                        <div className="share-box">
                                          <div className="d-flex align-items-center">
                                            <a
                                              href={generateFacebookShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-facebook-fill"></i>
                                            </a>
                                            <a
                                              href={generateTwitterShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico"
                                              tabIndex="0"
                                            >
                                              <i className="ri-twitter-fill"></i>
                                            </a>
                                            <a
                                              href={generateWhatsappShareUrl(
                                                data,
                                                source,
                                                data?.category
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="share-ico iq-copy-link"
                                              tabIndex="0"
                                            >
                                              <i className="ri-whatsapp-fill"></i>
                                            </a>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <span>
                                          <i
                                            className={`ri-heart-fill ${
                                              favoriteItems.includes(data?._id)
                                                ? "red-heart"
                                                : ""
                                            }`}
                                            //   onClick={() =>
                                            //     handleFavClick(
                                            //       data?._id,
                                            //       data?.category
                                            //     )
                                            //   }
                                            title={
                                              favoriteItems.includes(data?._id)
                                                ? t("Remove From Favourite")
                                                : t("Add To Favourite")
                                            }
                                          ></i>
                                        </span>
                                        {/* <span className="count-box">19+</span> */}
                                      </li>
                                      <li>
                                        <span>
                                          <i
                                            className={`ri-add-line ${
                                              watchListItems.includes(data?._id)
                                                ? "red-heart"
                                                : ""
                                            }`}
                                            //   onClick={() =>
                                            //     handleWatchList(
                                            //       data?._id,
                                            //       data?.category
                                            //     )
                                            //   }
                                            title={
                                              watchListItems.includes(data?._id)
                                                ? t("Remove From WatchList")
                                                : t("Add To WatchList")
                                            }
                                          ></i>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div className="d-flex mt-4 justify-content-between align-items-center">
                                    <div className="w-90">
                                        <h6 className="" style={{fontSize : '18px', marginBottom : '0px' , padding : "5px"}}>
                                        {data?.category === "movie" ||
                                          data?.category === "video" ? (currentSelectedLanguage === 'fr' ? data?.media?.title:  data?.media?.translated_content?.find(e =>  e.language_code === currentSelectedLanguage)?.title_translated ) : data?.title
                                        }
                                          </h6>
                                    </div>
                                    <div className="w-10">
                                    <div className="badge badge-secondary p-1" style={{fontSize : '70%'}}> 
                                    {data?.category === "movie" ||
                                          data?.category === "video"
                                          ? convertSecondsToHoursMinutes(data?.media?.duration?.toFixed(2))
                                          : data?.category === "ads"
                                            ? null
                                            : `${data?.episodes?.length} ${data?.episodes?.length === 1
                                              ? "Episode"
                                              : "Episodes"
                                            }`}
                                      </div>
                                    </div>
                                  </div>

                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container fluid>
              <Row>
                <Col sm="12" className="overflow-hidden">
                  <div
                    className="gallery-container"
                    style={{ marginTop: "20px", marginBottom: "10px" }}
                  >
                    <div
                      className=" d-flex justify-content-center align-items-center mt-5 w-100"
                      style={{ marginTop: "20vh" }}
                    >
                      <h4>{t("No videos found!")}</h4>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </section>
      )}
    </>
  );
};

export default SearchPage;
